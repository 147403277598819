export const MenuItems = [
  {
    title: "Hair",
    path: "/services/hair",
    cName: "dropdown-link",
  },
  {
    title: "Brow",
    path: "/services/brow",
    cName: "dropdown-link",
  },
  {
    title: "Facials",
    path: "/services/facials",
    cName: "dropdown-link",
  },
  {
    title: "Makeup",
    path: "/services/makeup",
    cName: "dropdown-link",
  },
  {
    title: "Teeth whitening",
    path: "/services/teeth-whitening",
    cName: "dropdown-link",
  },
  {
    title: "Body Sculpting",
    path: "/services/body-sculpting ",
    cName: "dropdown-link",
  },
];
