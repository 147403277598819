import React, { useEffect, useRef } from "react";
import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";
import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";
import "./serviceslider.scss";
import PrimaryWidth from "../PrimaryWidth";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const makeup = "/home_services/make up.webp";
const hair = "/home_services/braiding.webp";
const facials = "/home_services/Facials.webp";
const brow = "/home_services/brow.webp";
const teeth = "/home_services/Teeth whitening.webp";
const body = "/home_services/body sculpting.webp";

const StyledText = styled.div`
  margin-top: 0.6rem;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  @media screen and (min-width: 600px) and (max-width: 960px) {
    font-size: 18px;
  }
  @media screen and (min-width: 960px) and (max-width: 1920px) {
    font-size: 22px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 24px;
  }
`;
const ServicesSlider = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const mapRange = (
      inputLower,
      inputUpper,
      outputLower,
      outputUpper,
      value
    ) => {
      const INPUT_RANGE = inputUpper - inputLower;
      const OUTPUT_RANGE = outputUpper - outputLower;
      return (
        outputLower + (((value - inputLower) / INPUT_RANGE) * OUTPUT_RANGE || 0)
      );
    };
    const options = {
      Dots: false,
      slidesPerPage: 1,
      Autoplay: {
        timeout: 2000,
      },
      on: {
        "Panzoom.beforeTransform": (carousel) => {
          carousel.slides.map((slide) => {
            const progress = carousel.getProgress(slide.index);
            const scale = mapRange(0, 1, 1, 1.2, 1 - Math.abs(progress));

            slide.el.style.setProperty("--f-scale", scale);
            slide.el.style.setProperty("--f-translateX", `${progress * -10}%`);
          });
        },
      },
    };
    const myCarousel = new Carousel(carouselRef.current, options, { Autoplay });

    return () => {
      myCarousel.destroy();
    };
  }, []);

  return (
    <>
      <PrimaryWidth>
        <h1
          className="headers_f"
          style={{ fontSize: "2.3rem", fontWeight: 700, marginTop: "2rem" }}
        >
          Our Services
        </h1>
        <StyledText>
          <p>
            Discover Beauty and Elegance Experience a world of beauty and
            elegance at our salon. Our expert team offers tailored services,
            from teeth whitening to makeup, hair, brows, and facials. Indulge in
            luxurious treatments that enhance your natural radiance. Unveil your
            true beauty and embrace confidence with us.
          </p>
        </StyledText>
        <div
          className="f-carousel"
          ref={carouselRef}
          id="servicesCarousel"
          style={{ width: "100%" }}
        >
          <div className="f-carousel__slide">
            <Link to="services/makeup">
              <div className="bg-white rounded-lg shadow-lg ">
                <img
                  className="w-full object-cover rounded-t-lg"
                  src={process.env.PUBLIC_URL + makeup}
                  alt="Makeup"
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-receipt-details md:text-receipt-date-lg uppercase font-normal">
                    Makeup
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="f-carousel__slide">
            <Link to="services/hair">
              <div className="bg-white rounded-lg shadow-lg ">
                <img
                  className="w-full object-cover rounded-t-lg"
                  src={process.env.PUBLIC_URL + hair}
                  alt="Hair"
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-receipt-details md:text-receipt-date-lg uppercase font-normal">
                    Hair
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="f-carousel__slide">
            <Link to="services/makeup">
              <div className="bg-white rounded-lg shadow-lg ">
                <img
                  className="w-full object-cover rounded-t-lg"
                  src={process.env.PUBLIC_URL + makeup}
                  alt="Makeup"
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-receipt-details md:text-receipt-date-lg uppercase font-normal">
                    Makeup
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="f-carousel__slide">
            <Link to="services/facials">
              <div className="bg-white rounded-lg shadow-lg ">
                <img
                  className="w-full object-cover rounded-t-lg"
                  src={process.env.PUBLIC_URL + facials}
                  alt="Facials"
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-receipt-details md:text-receipt-date-lg uppercase font-normal">
                    Facials
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="f-carousel__slide">
            <Link to="services/teeth-whitening">
              <div className="bg-white rounded-lg shadow-lg ">
                <img
                  className="w-full object-cover rounded-t-lg"
                  src={process.env.PUBLIC_URL + teeth}
                  alt="Teeth Whitening"
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-receipt-details md:text-receipt-date-lg uppercase font-normal">
                    Teeth Whitening
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="f-carousel__slide">
            <Link to="services/body-sculpting">
              <div className="bg-white rounded-lg shadow-lg ">
                <img
                  className="w-full object-cover rounded-t-lg"
                  src={process.env.PUBLIC_URL + body}
                  alt="Body Sculpting"
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-receipt-details md:text-receipt-date-lg uppercase font-normal">
                    Body Sculpting
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="f-carousel__slide">
            <Link to="services/brow">
              <div className="bg-white rounded-lg shadow-lg ">
                <img
                  className="w-full object-cover rounded-t-lg"
                  src={process.env.PUBLIC_URL + brow}
                  alt="Brows"
                  loading="lazy"
                />
                <div className="p-4">
                  <h3 className="text-receipt-details md:text-receipt-date-lg uppercase font-normal">
                    Brows
                  </h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </PrimaryWidth>
    </>
  );
};

export default ServicesSlider;
