import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Button,
  Input,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Container,
  FormControl,
  Select,
  MenuItem,
  Stack,
  IconButton,
} from "@mui/material";
import { BiEdit } from "react-icons/bi";
import { styled as muiStyled } from "@mui/material/styles";
import cities from "../../../Assets/data/cities.json";
import useAxiosSecured from "../../../Hooks/useAxiosSecured";
import { useSnackbar } from "notistack";
import styled from "@emotion/styled";
import AccountPopover from "../../../Components/Header/AccountPopover";
// import { axiosSecured } from "../../../Services/axiosInstance";
const ResponsiveContainer = styled(Container)`
  background: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  width: 80%;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    width: 90%;
    padding: 10px;
  }
`;
const StyledButton = styled(Button)`
  background: #fb464c !important;
  color: #fff !important;
  &:hover {
    background: #ff0000;
    color: #fff;
  }
`;

const StyledTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "#8b7d7d",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));
const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== "focusColor",
})((props) => ({
  // input label when focused
  "& .Mui-focused": {
    color: props.focusColor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: props.focusColor,
  },

  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: props.focusColor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: props.focusColor,
    },
  },
}));
const StyledInputLabel = muiStyled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  "&.Mui-focused": {
    color: "#4f5672",
  },
}));
const TopTextDiv = muiStyled("div")(({ theme }) => ({
  width: { xs: "100%", sm: "60%" },
  margin: "0 auto",
  paddingBottom: "1.5rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // gap: "1rem"
}));
const StyledBox = styled(Box)`
  margin-bottom: 1.5rem;
`;
const StyledButtonContainer = styled("div")({
  //   border: '1px solid blue',
  marginTop: "2rem",
  display: "flex",
  justifyContent: "flex-end",
});

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const UserProfile = () => {
  // Editor state
  const [isEditable, setIsEditable] = React.useState(false);
  //   Value state change
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const axiosSecured = useAxiosSecured();
  // Input State
  const [inputValues, setInputValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
  });

  React.useEffect(() => {
    axiosSecured
      .get("/fetch/profile")
      .then((response) => {
        const { firstName, lastName, email, location, phone } =
          response.data.payload;
        setInputValues({
          firstName,
          lastName,
          email,
          phone,
          location,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          enqueueSnackbar(
            "Sorry You are not authorized to view this resource",
            { variant: "error" }
          );
        } else {
          const message = error.response.data?.payload;
          enqueueSnackbar("Sorry, Some thing went wrong", { variant: "error" });
        }
      });
  }, []);

  const SERVER_ERROR_MSG =
    "Sorry, Something went wrong.Our technical team are working to resolve the Issue";

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      setInputValues({ ...inputValues, firstName: value });
    } else if (name === "lastName") {
      setInputValues({ ...inputValues, lastName: value });
    } else if (name === "phone") {
      setInputValues({ ...inputValues, phone: value });
    } else if (name === "location") {
      setInputValues({ ...inputValues, location: value });
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async () => {
    const { firstName, lastName, phone, location } = inputValues;
    setIsEditable(false);
    axiosSecured
      .post("/update/profile", {
        firstName,
        lastName,
        phone,
        location,
      })
      .then((response) => {
        const data = response.data.payload;
        if (response.data.success) {
          setInputValues(data);
        }
        enqueueSnackbar("Profile update Successful!", { variant: "success" });
      })
      .catch((error) => {
        const message = error.response.data?.payload;
        if (error.response.status === 400) {
          enqueueSnackbar(message, { variant: "error" });
        } else {
          enqueueSnackbar(SERVER_ERROR_MSG, { variant: "error" });
        }
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: { xs: "100%", md: "80%" } }}>
        <Paper
          sx={{
            marginTop: { xs: "0rem", md: "3rem" },
            padding: { xs: "0.5rem", sm: "1.5rem" },
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          }}
          elevation={1}
        >
          <TopTextDiv>
            <Typography
              variant="p"
              color="#666362"
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                marginLeft: "10px",
              }}
              gutterBottom
            >
              Hello,{" "}
              <span
                style={{ fontWeight: 500, fontSize: "1rem" }}
              >{` ${inputValues.lastName}`}</span>
            </Typography>
            <IconButton>
              <BiEdit
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={() => setIsEditable(!isEditable)}
                title="Edit Profile"
              />
            </IconButton>
          </TopTextDiv>

          <Grid item xs={12} sm={6} md={3}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent={{ xs: "none", md: "space-between" }}
            >
              <StyledBox
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "95%" },
                  width: { xs: "100%", sm: "45%" },
                }}
                noValidate
                autoComplete="off"
              >
                <StyledTextField
                  disabled={!isEditable ? true : false}
                  fullWidth
                  id="fullWidth"
                  label="First Name"
                  name="firstName"
                  focusColor="#8b7d7d"
                  variant="standard"
                  // sx={{width: "70%"}}
                  value={inputValues.firstName}
                  onChange={handleInputChange}
                />
              </StyledBox>
              <StyledBox
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "95%" },
                  width: { xs: "100%", sm: "45%" },
                }}
                noValidate
                autoComplete="off"
              >
                <StyledTextField
                  disabled={!isEditable ? true : false}
                  fullWidth
                  id="fullWidth"
                  label="Last Name"
                  name="lastName"
                  focusColor="#8b7d7d"
                  variant="standard"
                  value={inputValues.lastName}
                  onChange={handleInputChange}
                />
              </StyledBox>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent={{ xs: "none", md: "space-between" }}
            >
              <StyledBox
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "95%" },
                  width: { xs: "100%", sm: "45%" },
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl variant="standard">
                  <StyledTextField
                    disabled
                    id="standard-basic"
                    label="Email"
                    focusColor="#8b7d7d"
                    variant="standard"
                    name="email"
                    value={inputValues.email ?? ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </StyledBox>
              <StyledBox
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "95%" },
                  width: { xs: "100%", sm: "45%" },
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl variant="standard">
                  <StyledTextField
                    disabled={!isEditable ? true : false}
                    id="standard-basic"
                    label="Phone Number"
                    focusColor="#8b7d7d"
                    variant="standard"
                    name="phone"
                    value={inputValues.phone ?? ""}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </StyledBox>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "none", md: "space-between" }}
            >
              <StyledBox
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "95%" },
                  width: { xs: "100%", sm: "45%" },
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  // className={classes.formControl}
                >
                  <StyledInputLabel id="demo-simple-select-standard-label">
                    Location
                  </StyledInputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={inputValues.location}
                    onChange={handleInputChange}
                    name="location"
                    disabled={!isEditable ? true : false}
                    label="Location"
                    sx={{
                      color: "black",
                      "&:focus": {
                        color: "blue",
                        backgroundColor: "red",
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    <MenuItem value={inputValues.location}>
                      {inputValues.location}
                    </MenuItem>
                    {cities.map((option) => (
                      <MenuItem key={option.abbreviation} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledBox>
            </Stack>

            <StyledButtonContainer>
              <StyledButton
                variant="contained"
                disableElevation
                onClick={handleSubmit}
                disabled={!isEditable ? true : false}
              >
                <span style={{ textTransform: "capitalize" }}>
                  Update Profile
                </span>
              </StyledButton>
            </StyledButtonContainer>
          </Grid>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default UserProfile;
