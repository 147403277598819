import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

// import {
//   REACT_APP_SERVER_URL,
//   REACT_APP_DOMAIN_NAME,
// } from "../../Utils/envVariables";
const { REACT_APP_SERVER_URL, REACT_APP_DOMAIN_NAME } = process.env;
const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;
const PayPalPayment = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initialOptions = {
    "client-id": REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  const [item, setItem] = useState("");
  const [amount, setAmount] = useState("");
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("payment"));
    if (items) {
      const amountToBePaid = 30;
      const itemPaidFor = items.item;
      setAmount(amountToBePaid.toString());
      setItem(itemPaidFor);
    }
  }, []);

  const serverUrl = REACT_APP_SERVER_URL;

  const createOrder = (data) => {
    // Order is created on the server and the order id is returned
    return fetch(`${serverUrl}/my-server/create-paypal-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      // Fetching the description and the cost from LocalStorage
      body: JSON.stringify({
        product: {
          description: item,
          cost: amount,
        },
      }),
    })
      .then((response) => response.json())
      .then((order) => order.id);
  };
  const onApprove = (data) => {
    // Order is captured on the server and the response is returned to the browser
    return fetch(`${serverUrl}/my-server/capture-paypal-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: data.orderID,
        serviceName: item,
        amount,
        customerEmail: auth?.email,
      }),
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const status = data.status;

        if (status?.toLowerCase() === "completed") {
          navigate(`/receipt`);
        }
      })
      .catch((error) => {
        enqueueSnackbar("Sorry, Something went wrong.Please try again", {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <PayPalScriptProvider options={initialOptions}>
        <div style={{ width: "350px" }}>
          <PayPalButtons
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApprove(data, actions)}
            style={{
              color: "blue",
              layout: "horizontal",
              height: 48,
              width: 100,
              tagline: false,
              shape: "rect",
            }}
          />
        </div>
      </PayPalScriptProvider>
    </div>
  );
};

export default PayPalPayment;
