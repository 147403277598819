import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import useAuth from "./useAuth";
import { axiosInstance } from "../Services/axiosInstance";
import axios from "axios";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axiosInstance.get("/refresh", {
      withCredentials: true,
    });

    setAuth((prev) => {
      return {
        ...prev,
        _id: response.data.payload._id,
        accessToken: response.data.payload.accessToken,
        role: response.data.payload.role,
        email: response.data.payload.email,
        firstName: response.data.payload.firstName,
        lastName: response.data.payload.lastName,
      };
    });

    // console.log(response.data.payload.accessToken);
    // Cookies.set("jwt", response.data.payload.accessToken);
    console.log(response.data.payload.accessToken);
    return response.data.payload.accessToken;
  };

  return refresh;
};

export default useRefreshToken;
