import React, { useState } from "react";
import { useLocation } from "react-router";
import styled from "@emotion/styled";
import { Box, Toolbar, Typography, Divider, Stack } from "@mui/material";
// import useStyles from "./styles";
// import UserDashboardTable from "./table/UserTable";
import Header from "./header/Header";
import Sidebar from "./components/SidebarProfile";
import UserProfile from "./profile/UserProfile";

const UserProfileView = () => {
  return (
    <Box
      sx={{
        paddingBottom: "2rem",
        backgroundColor: "#F6F6F6",
        height: "100vh",
      }}
    >
      <Header />

      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{ gap: { xs: "1rem", md: "2rem" }, padding:{xs: "0 1rem", md:"0 1rem 0 0" } }}
      >
        <Sidebar />

        <UserProfile />
      </Stack>
    </Box>
  );
};

export default UserProfileView;
