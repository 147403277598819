import styled from "@emotion/styled";
import React from "react";
import "./ErrorsStyle.scss";
import { useNavigate } from "react-router-dom";
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  font-family: "poppins", sans-serif;
  border: none !important;
`;
function Error401() {
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <div class="content flex-stack">
        <div class="noentry">
          <div class="ban"></div>
          <div class="messagebox">
            <h2>RESTRICTED ACCESS</h2>
            <p>
              Access to this page is restricted to registered customers only
            </p>
            <div class="buttoncontainer">
              <div class="button" onClick={() => navigate(-1)}>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cursor"></div>
    </StyledContainer>
  );
}

export default Error401;
