import { useContext, useDebugValue } from "react";
import { AuthContext } from "../Context/Auth/AuthContextProvider";

const useAuth = () => {
  const { auth } = useContext(AuthContext);

  useDebugValue(auth, (auth) => (auth?._id ? "logged in" : "Logged out"));

  return useContext(AuthContext);
};

export default useAuth;
