import axios from "axios";
import useRefreshToken from "../Hooks/useRefreshToken";
import useAuth from "../Hooks/useAuth";
import jwt_decode from "jwt-decode";
export const axiosInstance = axios.create({
  // change to http://localhost:5000 in dev
  // baseURL: "https://basilumeozulu.com/api/v1",
  // baseURL: "https://api.danritbeautysalon.com/api/v1",
  baseURL: "https://api.danritbeautysalon.com/api/v1",

  // baseURL: "http://api.danritbeautysalon.com",
  // headers: { "Content-Type": "application/json" },
  // timeout: 5000, // request timeouthttp://localhost:5000
  withCredentials: true,
});

export const axiosSecured = axios.create({
  // change to http://localhost:5000P
  // baseURL: "http://api.danritbeautysalon.com",

  baseURL: "https://api.danritbeautysalon.com/api/v1",
  // baseURL: "https://api.danritbeautysalon.com/api/v1",
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
