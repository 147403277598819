import { useState, useEffect } from "react";
import { ElfsightWidget } from "react-elfsight-widget";

const useScript = (url, name) => {
  const [lib, setLib] = useState({});

  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.onload = () => setLib({ [name]: window[name] });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return lib;
};

function InstagramFeed() {
  const instaStyle = {
    display: "flex",
    maxWidth: "1100px",
    width: "80%",
    margin: "0 auto",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    // { result },
    <div style={instaStyle}>
      <ElfsightWidget widgetID="93cbff90-b4bc-4d5f-bb50-96833a6dde61" />
      {/* <div className="elfsight-app-2a9622ad-dd57-46d5-bf8b-40f4304df571"></div> */}
    </div>
  );
}

export default InstagramFeed;
