import React, { useState } from "react";
import lashData from "../../Assets/data/lashextension.json";
import { motion } from "framer-motion";
import Booking from "./Booking";
import { TwContextProvider } from "../../Context/Booking/TwCalenderContextProvider";
import usePayment from "../../Hooks/usePayment";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import useAuth from "./../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { BrowContextProvider } from "../../Context/Booking/BrowCalenderContextProvider";

// const StyledCurve = styled.div`
//   width: 100%;

//   height: 50px;
//   // border: 1px solid blue;
//   background: #f2f5f9;
//   .curve {
//     width: 100%;
//     height: 50px;

//     // background-color: #f2f5f9;
//     background-color: #131b20;
//     width: 100%;
//     border-top-right-radius: 26px;
//     border-top-left-radius: 26px;
//   }
//   @media screen and (max-width: 960px) {
//     height: 20px;
//     .curve {
//       // background-color: #000;

//       height: 20px;
//       border-top-right-radius: 30px;
//       border-top-left-radius: 30px;
//     }
//   }
// }`;
const StyledImage = styled.img`
  width: 160px;
  border-radius: 50% !important;
  height: 160px;
  margin: 0 auto;
  display: flex;
  align-self: center;
`;

const Lash = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubServiceIndex, setSelectedSubServiceIndex] = useState(null);
  const [showBooking, setShowBooking] = useState(false);
  const [hideOtherSubServices, setHideOtherSubServices] = useState(false);
  const [paymentDetails, setPaymentDetailsAndLocalStorage] = usePayment();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [value, updateValue] = useLocalStorage("payment", {
    item: "",
    info: "",
    amount: null,
  });

  const handleCategorySelect = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
    }
    setSelectedSubServiceIndex(null);
    setShowBooking(false);
    setHideOtherSubServices(false);
  };

  const handleBookingToggle = (index) => {
    if (selectedSubServiceIndex === index && showBooking) {
      setSelectedSubServiceIndex(null);
      setShowBooking(false);
      setHideOtherSubServices(false);
    } else {
      setSelectedSubServiceIndex(index);
      setShowBooking(true);
      setHideOtherSubServices(true);
    }
  };

  return (
    <>
      <div className="flex flex-col">
        {lashData.map((lashExtension, index) => {
          const isCategorySelected =
            selectedCategory === lashExtension.category;
          const shouldRender = !selectedCategory || isCategorySelected;

          return (
            shouldRender && (
              <div key={index}>
                <div className="main-card shadow-sm bg-white flex-col flex sm:flex-row sm:justify-between items-center sm:items-end my-5  rounded py-3 sm:p-4">
                  <div className="flex flex-col sm:px-4 items-start md:ml-5 sm:w-full ">
                    <h2 className="text-[16px]  font-semibold sm:text-[18px] md:text-[22px]  text-black pl-2 w-full">
                      {lashExtension.category}
                    </h2>
                    <p className="mt-3 pl-2 sm:text-base  sm:w-full">
                      {lashExtension.info}
                    </p>
                    <p className=" mt-3 pl-2 w-full flex flex-row text-[12px] sm:text-base sm:pl-2 md:mb-6  font-bold">
                      {lashExtension.duration}&nbsp;
                    </p>
                  </div>

                  <button
                    id="select-btn"
                    className="bg-paymentReceipt mr-4 md:hover:bg-[#FF0000] !block border text-center rounded w-[50%] sm:w-[85px] text-white text-sm py-2 px-4"
                    onClick={() => handleCategorySelect(lashExtension.category)}
                  >
                    {isCategorySelected ? "Close" : "Open"}
                  </button>
                </div>

                {selectedCategory === lashExtension.category && (
                  <div className="subservice-card md:w-[98%] mx-auto md:flex md:flex-col">
                    {lashExtension.children.map((subService, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.3,
                          delay: 0,
                          ease: "easeInOut",
                        }}
                        exit={{ opacity: 0, scale: 0 }}
                        className={`${
                          hideOtherSubServices &&
                          selectedSubServiceIndex !== index
                            ? "hidden"
                            : "subservice"
                        } flex flex-col my-5 sm:w-full justify-between rounded ${
                          selectedSubServiceIndex === index &&
                          hideOtherSubServices &&
                          "active"
                        }`}
                      >
                        <div className="sm:flex-row p-4 bg-white flex flex-col rounded">
                          <StyledImage
                            src={process.env.PUBLIC_URL + subService.cardImage}
                            alt="subservice-img"
                            className=" mb-2 rounded"
                          />

                          <div className="flex flex-col items-start md:ml-5 sm:w-full justify-between ">
                            <h2 className="text-[16px]  font-semibold sm:text-[18px] md:text-[22px] text-black pl-2 w-full">
                              {subService.name}
                            </h2>
                            <p className="pl-2 sm:text-base sm:w-full">
                              {subService.info}
                            </p>
                            <p className="pl-2 w-full flex flex-row text-[12px] sm:text-base sm:pl-2 font-bold">
                              {subService.duration}&nbsp;
                              <span className="flex flex-row  font-bold">
                                @ {subService.cost}
                              </span>
                            </p>
                          </div>
                          <div className="flex flex-row mt-3 sm:mt-0 items-center px-2 justify-center sm:items-end md:justify-start">
                            <button
                              className="bg-paymentReceipt md:hover:bg-[#FF0000] w-[50%] sm:w-[100px] border !block rounded text-white align-bottom  text-[10px] sm:text-[12px] py-2 px-2 sm:py-2 sm:px-4"
                              onClick={() => {
                                handleBookingToggle(index);
                                updateValue({
                                  info: `${subService.info}\n ${subService.duration}`,
                                  item: subService.name,
                                  amount: subService.cost?.split("$")[1],
                                  serviceType: "Brow",
                                  startingPrice: subService.cost
                                    ?.split("$")[1]
                                    .toString(),
                                });
                              }}
                            >
                              {selectedSubServiceIndex === index &&
                              showBooking ? (
                                <span>Cancel</span>
                              ) : (
                                <span>Book</span>
                              )}
                            </button>
                          </div>
                        </div>
                        {selectedSubServiceIndex === index &&
                          hideOtherSubServices && (
                            <motion.div
                              key={index}
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{
                                duration: 0.3,
                                delay: 0,
                                ease: "easeInOut",
                              }}
                              exit={{ opacity: 0, scale: 0 }}
                              className="booking-wrapper"
                            >
                              <BrowContextProvider>
                                <Booking />
                              </BrowContextProvider>
                            </motion.div>
                          )}
                      </motion.div>
                    ))}
                  </div>
                )}
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

export default Lash;
