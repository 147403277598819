import React from "react";

const Map = () => {
  return (
    <div style={{ width: "100%", height: "80vh" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1550.5811518344924!2d-76.823493!3d38.988792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7e9793546ebdd%3A0x6b50285e950a623b!2sDanRit%20hair%20braiding%20and%20styling%20salon!5e0!3m2!1sen!2sus!4v1686768228350!5m2!1sen!2sus"
        width="100%"
        height="100%"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default Map;
