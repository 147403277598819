import ReactDOM from "react-dom/client";

import App from "./App";
// import Test from "./Test";
import "./index.css";

// import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import Error500Page from "./Components/ErrorBoundary/Errors/Error500/Error500Page";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import { AuthContextProvider } from "./Context/Auth/AuthContextProvider";
import BodySculptingCards from "./Components/BodySculpting/BodySculptingCards";
import Makeup from "./Components/Makeup/Makeup";
import Loader from "./Components/LoadingScreen/CircleLoader";
import ListPlaceholder from "./Features/AdminDashboard/src/components/placeholder/ListPlaceholder";
// import Test from "./Pages/UserDashboard/test.jsx"
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <ErrorBoundary fallbackUI={<Error500Page />}>
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
  // </ErrorBoundary>
);

// If you want to enable client cache, register instead.
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
