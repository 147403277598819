import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaInfoCircle, FaTimes } from "react-icons/fa";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { AiFillCaretUp, AiOutlineCaretDown } from "react-icons/ai";
import { IoMdHome, IoMdSettings } from "react-icons/io";
import {
  MdOutlineConnectWithoutContact,
  MdOutlineSpaceDashboard,
  MdPolicy,
} from "react-icons/md";
import Swal from "sweetalert2";

// ===================================||  IMPORTS || ==================================
import DanritLogo from "../../Assets/Images/Icons/danlogo.svg";
import "./Navbar.scss";
import Dropdown from "./Dropdown";
import useAuth from "../../Hooks/useAuth";
import AccountPopover from "./AccountPopover";
import AnimateButton from "../extended/AnimateButton";
import useLogout from "../../Hooks/useLogout";
export const StyledButton = styled(Button)`
  background-color: #ff3336;
  color: #fff;
  width: 100%;
  min-width: 100px;
  &:hover {
    background-color: red;
    color: #fff;
  }
`;

const StyledAuthIndicatorCont = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`;

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const logout = useLogout();
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const hideDropDown = () => {};
  const handleLogOut = () => {
    Swal.fire({
      title: "Confirmation",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#9de0f6",
      cancelButtonColor: "#fb464c",
      focusConfirm: false,
      inputAutoFocus: false,
      backdrop: "static",
      allowOutsideClick: false,
      confirmButtonText: "Confirm",
      focusCancel: false,
      text: `Are you sure you want to logout?`,
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          await logout();
          navigate("/");
          closeMobileMenu();
        } else {
        }
      })
      .catch((error) => {
        closeMobileMenu();

        Swal.fire({
          title: "Error",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#9de0f6",
          cancelButtonColor: "#fb464c",
          focusConfirm: false,
          inputAutoFocus: false,
          backdrop: "static",
          allowOutsideClick: false,
          confirmButtonText: "Confirm",
          focusCancel: false,
          text: `Sorry, Something went wrong. Please try again`,
        });
      });
  };

  return (
    <>
      <nav className="navbar__container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={DanritLogo} alt="Logo" />
          <i class="fab fa-firstdraft" />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes color="#FC2F32" /> : <FaBars color="#FC2F32" />}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"} id="nav__links">
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              {window.innerWidth < 960 && (
                <IoMdHome style={{ fontSize: "1.5rem" }} />
              )}
              <span>Home</span>
            </Link>
          </li>
          <li
            className="nav-item"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={hideDropDown}
          >
            <Link
              to="/services"
              className="nav-links"
              onClick={closeMobileMenu}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {window.innerWidth < 960 && (
                <IoMdSettings style={{ fontSize: "1.5rem" }} />
              )}
              <span>Services</span>

              {window.innerWidth < 960 ? (
                <></>
              ) : (
                <>{dropdown ? <AiFillCaretUp /> : <AiOutlineCaretDown />} </>
              )}
            </Link>
            {dropdown && <Dropdown />}
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              {window.innerWidth < 960 && (
                <MdOutlineConnectWithoutContact
                  style={{ fontSize: "1.5rem" }}
                />
              )}

              <span>Contact </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
              {window.innerWidth < 960 && (
                <FaInfoCircle style={{ fontSize: "1.5rem" }} />
              )}

              <span>About </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/privacy-policy"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              {window.innerWidth < 960 && (
                <MdPolicy style={{ fontSize: "1.5rem" }} />
              )}

              <span>Privacy & Policy </span>
            </Link>
          </li>
          {/*  */}

          {auth._id ? (
            <>
              {window.innerWidth < 960 && (
                <li className="nav-item">
                  <Link
                    to={`${
                      auth.role === 3 || auth.role === 1
                        ? "/admin/dashboard/app"
                        : "/customer/dashboard"
                    }`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    <MdOutlineSpaceDashboard style={{ fontSize: "1.5rem" }} />

                    <span>Dashboard </span>
                  </Link>
                </li>
              )}
            </>
          ) : (
            <></>
          )}

          {/*  */}
          <li>
            {auth._id ? (
              <Link className="nav-links-mobile">
                <AnimateButton>
                  <StyledButton onClick={handleLogOut}>Sign Out</StyledButton>
                </AnimateButton>
              </Link>
            ) : (
              <Link
                to="/login"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                <AnimateButton>
                  <StyledButton>Sign In</StyledButton>
                </AnimateButton>
              </Link>
            )}
          </li>
        </ul>
        {auth._id ? (
          <>
            {window.innerWidth > 960 && (
              <>
                <StyledAuthIndicatorCont>
                  <AccountPopover fontSize={35} />
                </StyledAuthIndicatorCont>
                <h6
                  style={{
                    fontWeight: "bold",
                    color: "#FC2F32",
                    marginLeft: "0.4rem",
                    width: "100px",
                  }}
                >
                  {auth &&
                    ` ${auth.firstName.charAt(0)}   ${auth.lastName.charAt(0)}`}
                </h6>
              </>
            )}
          </>
        ) : (
          <>
            {!auth._id && (
              <Link to="/login">
                {window.innerWidth > 960 && (
                  <AnimateButton>
                    <StyledButton className="btn">Sign In</StyledButton>
                  </AnimateButton>
                )}
              </Link>
            )}
          </>
        )}
      </nav>
    </>
  );
}

export default Navbar;
