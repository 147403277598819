import styled from "@emotion/styled";
import ContactBg from "../../Assets/Images/contact-salon.jpg";
import makeupImg from "../../Assets/Images/makeup.jpg";
import { Grid } from "@mui/material";

export const StyledServicesContainer = styled.div`
  // border: 1px solid blue;
  background: #f3f5f9;
  font-family: poppins;
  width: 100%;
  // margin-top: 80px;
`;

export const StyledHeroContainer = styled.div`
  width: 100%;
  height: 70vh;
  // border: 1px solid red;
  background-image: url(${ContactBg});
  background-position: center;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: overlay;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledContent = styled.div`
  width: 70%;
  // height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid pink;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 1rem 0 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }
`;
export const StyledHeroTitle = styled.div`
  // width: 50%;
  font-size: 2.125rem !important;
  // border: 1px solid yellow;
  display: flex;
  justify-content: center;
  color: #fff;
  h1 {
    font-size: 5.875rem;
    font-family: poppins;
    font-weight: bold !important;
  }
`;
export const StyledHeroContent = styled.div`
  width: 70%;
  // height: 500px;
  // border: 1px solid green;
  font-weight: 600;
  color: #fff;
  font-family: poppins;
  text-align: center;
  font-size: 1.1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    // border: 1px solid green;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    // border: 1px solid green;
    line-height: 2rem;
  }
`;
export const StyledBannerContainer = styled(Grid)`
  // width: 100%;
  // // height: 500px;
  // // background-color: red;
  // border: 1px solid brown;
  // margin-top: 2rem;
  // // padding: 0 1.5rem;

  // @media (min-width: 600px) and (max-width: 959px) {
  // }
`;

export const StyledBannerHeader = styled.div`
  width: 100%;
  display: flex;
  color: #000;
  justify-content: center;
  // flex-direction: column;
  // border: 1px solid yellow;
  margin-bottom: 1.5rem;

  h1 {
    font-weight: bold;
    font-size: 2rem !important;
  }

  @media screen and (min-width: 1440px) {
    margin: 4rem 0;
  }
`;
export const StyledBannerContentBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  // flex-gap: 60px;
  // padding: 0 2rem;
  background: #f3f5f9;
  // position: relative;
  // border: 1px solid red;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    // align-items: flex-start;
    gap: 1.5rem;
    padding: 0 1rem;
    // height: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    margin-bottom: 2rem;
    margin-top: 1.2rem;
  }
`;
export const StyledBannerColumnOne = styled(Grid)`
  flex: 1;
  // border: 1px solid blue;
  // height: 400px;
  display: flex !important;
  justify-content: center;
  position: relative;
  align-items: center;
  background-position: center;
  // padding-left: 1rem;
  div {
    background: #fff;
    width: 500px;
    height: 400px;
    border-radius: 12px;
    position: relative;
    background-blend-mode: overlay;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    position: absolute;
    width: 380px;
    height: 380px;
    // object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    // background: #000;
    // width: 100%;
    display: none !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex: 1;
    display: block;
    div {
      background: #fff;
      width: 400px;
      height: 450px;
      border-radius: 12px;
      position: relative;
      background-blend-mode: overlay;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      position: absolute;
      width: 380px;
      height: 380px;
      // object-fit: cover;
    }
  }

  @media screen and (min-width: 1440px) {
    div {
      background: #fff;
      width: 720px;
      height: 470px;
    }

    img {
      position: absolute;
      width: 420px;
      height: 420px;
    }
  }
`;
export const StyledBannerColumnTwo = styled(Grid)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 600px) {
    max-width: 100% !important;
  }
  @media screen and (max-width: 768px) {
    // padding: 0;
    align-items: flex-start;
    width: 100%;
  }
  // border: 1px solid yellow;
`;
export const StyledBannerColumnTwoNavigationContainer = styled.div`
  width: 84%;
  // height: 450px;
  flex-direction: column;
  // border: 2px solid yellow;
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  // @media screen and (max-width: 768px) {
  //   width: 90% !important;
  // }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 90% !important;
  }
  @media screen and (min-width: 1440px) {
    width: 60%;
  }
`;
export const StyledSelector = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-content: center;

  color: #fff;
  background: red;
`;
export const StyledSelectorContainer = styled.div`
  width: 100%;
  height: 100px;
  transition: 0.7s;

  // border-radius: 4px;
  // color: #fff;
  background: blue;
`;

export const StyledServiceSection = styled.div`
  display: flex;
  justify-content: center;
  // align-items:center
  height: 100px;
  // background: yellow;
  width: 100%;
  div {
    text-align: center;

    // border: 1px solid blue;
    // width:100%;

    h1 {
      font-weight: bold !important;
      font-size: 2rem !important;
      font-weight: bold !important;
    }
    h4 {
      color: #949597;
    }
  }
`;

export const StyledCard = styled.div`
  margin: auto;
  height: 300px;
  width: 300px;
  border-radius: 12px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.04);
  margin-top: 1.5rem;
`;
export const StyledCardImage = styled.div`
  margin: auto;
  height: 176px;
  width: 100%;
  border-radius: 12px;
  border-radius: 12px;
  background: red;
`;
export const StyledCardImageInnerContainer = styled.div`
  height: 167px;
  width: 100%;
  border-radius: 10px;
  border-radius: 10px;
  // border-bottom-left-radius: 12px;
  // border-bottom-right-radius: 12px;
  // background-image: url(${makeupImg});
  background-position: center;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: yellow;
`;
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid blue;
  h3 {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
`;

export const StyledCardContainer = styled(Grid)`
  // display: flex;
  // flex-wrap: wrap;
  // gap: 1rem;
  // padding: 1rem 0 1rem 0;
  // margin-bottom: 1.6rem;
  // @media screen and (min-width: 1024px) and (max-width: 1439px) {
  //   padding: 1rem 1.5rem 1rem 1.5rem;
  // }
  // @media screen and (min-width: 1440px) {
  //   padding: 2rem 7rem 2rem 7rem;
  // }
`;

export const StyledCurve = styled.div`
  width: 100%;

  height: 50px;
  // border: 1px solid blue;
  background: #131b20;
  .curve {
    width: 100%;
    height: 50px;

    background-color: #f2f5f9;
    width: 100%;
    border-bottom-right-radius: 26px;
    border-bottom-left-radius: 26px;
  }
  @media screen and (max-width: 960px) {
    height: 20px;
    .curve {
      // background-color: #000;

      height: 20px;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
`;
