import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Images/Icons/danlogow.svg";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import "./footer.scss";
import { SiTiktok } from "react-icons/si";

const Footer = () => {
  const pages = [
    "Pages",
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: "Contact",
      link: "/contact",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "privacy and policy",
      link: "/privacy-policy",
    },
  ];
  const workingHours = [
    "Salon Hours",
    {
      name: "MON  8:30am – 8:00pm ",
      link: "/",
    },
    {
      name: "TUE   8:30am – 8:00pm ",
      link: "/",
    },
    {
      name: "WED   8:30am – 8:00pm",
      link: "/",
    },
    {
      name: "THR   8:30am – 8:00pm",
      link: "/",
    },
    {
      name: "FRI   8:30am – 8:00pm",
      link: "/",
    },
    {
      name: "SAT   8:30am – 8:00pm",
      link: "/",
    },
    {
      name: "SUN   11:00am – 7:00pm",
      link: "/",
    },
  ];
  const paymentPartners = [
    "Payment Methods",
    {
      name: "Master Card",
      link: "https://www.mastercard.com",
    },
    {
      name: "Visa",
      link: "https://usa.visa.com/",
    },
    {
      name: "Cash App",
      link: "https://stripe.com/",
    },
    {
      name: "American Express",
      link: "https://www.americanexpress.com/",
    },
    {
      name: "Discover",
      link: "https://www.discover.com/",
    },
    {
      name: "Union Pay",
      link: "https://www.unionpayintl.com/en/",
    },
    {
      name: "Paypal",
      link: "https://www.paypal.com/us/home",
    },
  ];
  const contacts = [
    "Contact",
    {
      name: "+1 240-906-1710",
      link: "/",
      icon: <FaPhoneAlt />,
    },
    {
      name: "info@danritbeautysalon.com",
      link: "/",
      icon: <MdEmail />,
    },
    {
      name: "10539 Greenbelt Rd Suite 103, Lanham, MD 20706, United States",
      link: "/",
      icon: <MdLocationPin />,
    },
  ];
  const socials = [
    {
      name: "Facebook",
      link: "https://web.facebook.com/Danritbeautysalon",
      icon: <FaFacebookF />,
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/danritbeautysalon/",
      icon: <BsInstagram />,
    },
    {
      name: "TickTok",
      link: "https://www.tiktok.com/@danritbeautysalon",
      icon: <SiTiktok />,
    },
  ];
  return (
    <footer className="footer">
      <div className="footer_links_sec">
        <div className="footer_link_sec1">
          <div className="logo">
            <img className="" src={logo} alt="Danrit-logo" width={"150px"} />
            {/* <h1>DanRit</h1> */}
          </div>
          <p>
            We are a one stop beauty salon. Our stylists understand that your
            hair style is a personal expression of who you are. So we are
            totally committed to working with our clients to understand what is
            the best style that will bring out their unique personality. CALL
            TODAY TO BOOK AN APPOINTMENT AND LET US STYLE TO MAKE YOU SMILE!
          </p>
        </div>
        <div className="flex_item">
          <div className="footer_link_sec2">
            <ul>
              <li id="head">{pages[0]}</li>
              {pages.map((page, index) => {
                return (
                  <Link to={page.link} style={{ color: "#fff" }} key={index}>
                    <li>{page.name}</li>
                  </Link>
                );
              })}
            </ul>
          </div>
          <div className="footer_link_sec3">
            <ul>
              <li id="head">{paymentPartners[0]}</li>
              {paymentPartners.map((partner, index) => {
                return (
                  <Link to={partner.link} style={{ color: "#fff" }} key={index}>
                    <li>{partner.name}</li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="footer_link_sec4">
          <div className="footer_link_sec4">
            <ul>
              <li id="head">{workingHours[0]}</li>
              {workingHours.map((working_hour, index) => {
                return <li key={index}>{working_hour.name}</li>;
              })}
            </ul>
          </div>
          <ul>
            <li id="head">{contacts[0]}</li>
            {contacts.map((contact, index) => {
              return (
                <a href={contact.link} style={{ color: "#fff" }} key={index}>
                  <li>
                    <span>{contact.icon}</span>
                    <span>{contact.name}</span>
                  </li>
                </a>
              );
            })}
          </ul>
        </div>
      </div>

      <hr />

      <div className="footer_links_sec2">
        <div className="footer_link_copyrite">
          <p>2023 &copy; DanRit Beauty Salon</p>
          <div className="flex_item2">
            <p>
              Powered by
              <a
                href="https://capacitybay.org/"
                target="_black"
                style={{ color: "#008001", marginLeft: "5px" }}
              >
                CapacityBay Inc
              </a>
            </p>
            <p>
              <Link to="/privacy-policy" style={{ color: "#fff" }}>
                Privacy & Policy
              </Link>
            </p>
          </div>
        </div>
        <div className="footer_media_icon">
          <ul>
            {socials.map((social, index) => {
              return (
                <a
                  href={social.link}
                  className="text-2xl"
                  style={{ color: "#fff" }}
                  key={index}
                >
                  <li>{social.icon}</li>
                </a>
              );
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
