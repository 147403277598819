import styled from "@emotion/styled";
import DatePicker from "react-datepicker";
import Button from "@mui/material/Button";

export const StyledDatepicker = styled(DatePicker)`
  background-color: #fc2f32;
  color: #fff;
  caret-color: transparent;

  border: none;
  padding: 8px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  outline: none;

  border-radius: 4px;
  ::placeholder {
    color: #fff;
  }
  .select__placeholder {
    color: #fff;
  }
  // .react-datepicker-wrapper {
  width: auto !important;
  // border 1px solid blue;

  // }

  .react-datepicker {
    width: 380px;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    width: 50px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
  }
`;
export const StyledButton = styled(Button)`
  background-color: #BrowContextProvider;
  color: #fff;
  width: 100px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:hover {
    background-color: red;
    color: #fff;
  }
`;
export const StyledSelectInput = styled.select`
  //   appearance: none;
  outline: none;
  background-color: #fc2f32;
  border: none;
  font-size: 16px;
  padding: 6px 10px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #ccc;

  &::after {
    content: "\\25BC";
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #555;
  }

  &:focus {
    outline: none;
  }

  option:checked {
    background-color: #03a9f4;
    color: #fff;
  }

  option {
    background-color: #fff;
    color: #000;
  }

  ::placeholder {
    color: #fff;
  }
  color: #fff;
`;

export const StyledTimeParentContainer = styled.div`
  width: 400px;
  display: grid;
  // justify-content: space-around;
  grid-template-columns: repeat(3, 1fr);
  // border: 1px solid red;
  margin-left: auto;
  flex-wrap: wrap;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  @media screen and (max-width: 600px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
    height: 300px;
    margin-bottom: 1rem;
  }
  @media screen and (min-width: 440px) and (max-width: 480px) {
    width: 350px;
  }
`;
export const StyledTimeParentContainer2 = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
  margin-left: auto;
  flex-wrap: wrap;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`;
export const StyledTimeContainer = styled.div`
  justify-self: center;
  align-self: center;
  background-color: #fff;
  width: 70px;
  height: 50px;
  color: #000;
  display: flex;
  border: 1px solid #d5addc;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    background: #e5e5e5;
    cursor: pointer;
  }
`;
export const StyledPlaceHolder = styled.div`
  // width: 100%;
  width: 400px;
  margin-left: auto;
  // border: 1px solid blue;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  h3 {
    font-weight: 600;
    // color: red;
    font-size: 1rem;
  }
  @media screen and (max-width: 580px) {
    margin: auto;
    width: 100%;
  }
`;
