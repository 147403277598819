import "./service_details.css";
import { Link } from "react-router-dom";

const PageBanner = (props) => {
  return (
    <>
      <section class="c-section">
        <div className="text__wrap">
          <h2 class="c-section__title">
            <span>{props.serviceName}</span>
          </h2>
          <p className="__service_para">
            <Link to="/" style={{ color: "#000" }}>
              {" "}
              Home{" "}
            </Link>{" "}
            {">"}{" "}
            {!props.pageName ? (
              ""
            ) : (
              <Link to={props.pageLink} style={{ color: "#000" }}>
                {" "}
                {props.pageName}{" "}
              </Link>
            )}{" "}
            {!props.pageName ? "" : ">"} {props.serviceName}
          </p>
          <p className="text__below">{props.serviceText}</p>
        </div>
        <div
          className="img__wrap"
          style={{ backgroundImage: `url(${props.image})` }}
        ></div>
      </section>
    </>
  );
};

export default PageBanner;
