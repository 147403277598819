import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { axiosInstance, axiosSecured } from "../../Services/axiosInstance";
import useAuth from "./../../Hooks/useAuth";
import { StyledButtonContainer } from "./styles";
import styled from "@emotion/styled";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import backgroundImage from "../../Assets/Images/login_bg_desktop.png";

import moment from "moment";
import { checkAMorPM } from "../../Utils/helpers";
// import {
//   REACT_APP_DOMAIN_NAME,
//   REACT_APP_ENV,
//   REACT_APP_SERVER_URL,
// } from "../../Utils/envVariables";
const { REACT_APP_DOMAIN_NAME } = process.env;
const StyledPaymentContainer = styled.section`
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;

  form {
    background-color: #fff;
  }

  #submit {
    background-color: #fd4447;
  }

  .preview__container {
    padding-top: 1rem;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    border-radius: 6px;
    ul {
      padding: 1rem;
    }
    h6 {
      font-weight: bold !important;
      text-align: center;
    }
  }

  .payment__property {
    font-weight: 600;
  }
`;

const clearLocalStorageProperty = (propertyKey) => {
  localStorage.removeItem(propertyKey);
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useAuth();
  const [value, updateValue] = useLocalStorage("payment", {
    appointmentId: null,
  });
  const [selectedTimeValue, updateSelectedTimeValue] = useLocalStorage(
    "selectedDuration",
    {
      selectedDate: [],
      selectedTime: null,
    }
  );
  const { selectedDate, selectedTime } = selectedTimeValue;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: `https://danritbeautysalon.com/receipt`,
        // return_url: `${REACT_APP_DOMAIN_NAME}/receipt`,
        return_url: `http://localhost:3000/receipt`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <StyledPaymentContainer>
      <form id="payment-form" onSubmit={handleSubmit}>
        <div className="preview__container">
          <h6>Payment Summary</h6>
          <ul>
            <li>
              <span className="payment__property">Service</span> : {value.item}
            </li>
            <li>
              <span className="payment__property">Starting Price</span> : &nbsp;{" "}
              {`$${parseFloat(
                value.amount.replace("@", "").replace(/\s+/g, "")
              ).toFixed(2)}`}
            </li>
            <li>
              <span className="payment__property">Commitment Fee</span> : $
              {commitmentAmt(value.amount).toFixed(2)}
            </li>
            <li>
              <span className="payment__property">Payment Charge</span> : $ 1.20
            </li>
            <li>
              <span className="payment__property">Total Payment</span> : $
              {commitmentAmt(value.amount) + 1.2}0
            </li>
            {/* <li>
              <span className="payment__property">Appointment Date</span> :{" "}
              {selectedTimeValue.selectedDate}
            </li>
            <li>
              <span className="payment__property">Appointment Time</span> :{" "}
              {selectedTimeValue.selectedTime}
              {checkAMorPM(selectedTimeValue.selectedTime)} (EST)
            </li> */}
          </ul>
        </div>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <StyledButtonContainer>
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
        </StyledButtonContainer>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </StyledPaymentContainer>
  );
};

const commitmentAmt = (value) => {
  const value2 = 300;
  const tenPercent = value2 * 0.1;

  return tenPercent;
};

export default CheckoutForm;
