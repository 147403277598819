import { useState, useEffect } from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import "./BeforeAfter.scss";
function BeforeAfter(props) {
  const FIRST_IMAGE = {
    imageUrl: props.firstIMG,
  };
  const SECOND_IMAGE = {
    imageUrl: props.secondIMG,
  };
  const instaStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundPosition: "center center",
  };
  const conStyle = {
    width: "500px",
    height: "500px",
    overflow: "hidden",

    // borderBottomRightRadius: "10px",
  };

  return (
    // { result },
    <div style={conStyle} className="before_con">
      <h1
        className="beforeAfter__font-title"
        style={{
          fontSize: "2.3rem",
          textAlign: "center",
          marginBottom: "1rem",
          display: props.showHeader ? "block" : "none",
        }}
      >
        Before And After
        {/* Gallery */}
      </h1>
      <ReactBeforeSliderComponent
        firstImage={FIRST_IMAGE}
        secondImage={SECOND_IMAGE}
        style={instaStyle}
      />
    </div>
  );
}

export default BeforeAfter;
