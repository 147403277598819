import React from "react";

const style = {
  maxWidth: "1200px",
  width: "95%",
  backgroundColor: "#F2F5F9",
  display: "flex",
  flexWrap: "wrap",
  margin: "0 auto",
  justifyContent: "center",
};

const PrimaryWidth = (props) => {
  return <div style={style}>{props.children}</div>;
};

export default PrimaryWidth;
