import useAuth from "../../Hooks/useAuth";
import { useLocation, useNavigate, Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ requiredRoles }) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const location = useLocation();

  return auth._id ? (
    requiredRoles.includes(auth?.role) ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    )
  ) : (
    // <Outlet />
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
