import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledParentContainer = styled.div`
  // width: 100%;
  background: #fff;
  // border: 1px solid blue;
`;

export const StyledContainer = styled.div`
  background-color: #f5f7fa;
  max-width: 500px;
  border-radius: 6px;
  // max-width: 500px;
  // border: 1px solid blue;
  margin: 3rem auto;
  // min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background: #f5f7fa;
  height: 450px;
  padding: 1.5rem;
  @media (max-width: 576px) {
    font-size: 15px;
  }
`;
export const StyledCard = styled.div`
  min-width: 100%;
  // border: 1px solid red;
  padding: 10px 14px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  // align-center;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledInnerContainer = styled.div`
  width: 100%;
  // border: 1px solid yellow;
  height: 150px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
export const StyledSelector = styled.div`
min-width: 400px;
border: 1px solid red;
padding: 6px 12px;
background: #fff;
border-radius: 6px;
display:flex;
justify-content:space-between;
align-center;
`;

export const StyledButton = styled(Button)`
  background: #fb464c;
  &:hover {
    background: #ff0000;
    color: #fff;
  }
`;
