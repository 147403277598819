import { useEffect, createContext, useState } from "react";
import { axiosInstance } from "../../Services/axiosInstance";

export const TwContext = createContext();
export function TwContextProvider({ children }) {
  const [calender, setCalender] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disableInput, setDisableInput] = useState({
    button: true,
    select: true,
  });
  useEffect(() => {
    let isCancelled = false;
    setLoading(true);
    axiosInstance
      .get("/fetch/makeup/calender-dates")

      .then((data) => {
        if (!isCancelled) {
          setCalender(data);

          setLoading(false);
          setError("");
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);

        setCalender([]);
      });

    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <TwContext.Provider
      value={{
        getCalender: [calender, setCalender],
        getInputState: [disableInput, setDisableInput],
      }}
    >
      {children}
    </TwContext.Provider>
  );
}
