import * as React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { TbCalendarTime } from "react-icons/tb";
import { AiFillHome } from "react-icons/ai";
import { FaCalendarPlus } from "react-icons/fa";
import { HiUser } from "react-icons/hi";
import { BiLogOut } from "react-icons/bi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useLogout from "../../../Hooks/useLogout";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { useState } from "react";

const SidebarProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logOut = useLogout();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [color, setColor] = useState({
    appointment: false,
    profile: false,
    create: false,
    home: false,
    logout: false,
  });

  const handleLogOut = () => {
    Swal.fire({
      title: "Confirmation",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#9de0f6",
      cancelButtonColor: "#fb464c",
      focusConfirm: false,
      inputAutoFocus: false,
      backdrop: "static",
      allowOutsideClick: false,
      confirmButtonText: "Confirm",
      focusCancel: false,
      text: `Are you sure you want to logout?`,
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          await logOut();
          navigate("/");
        }
      })
      .catch((error) => {
        enqueueSnackbar("Sorry, Something went wrong. Please try again", {
          variant: "error",
        });
      });
  };

  const setColorOnHover = (input) => {
    setColor((prevColor) => ({ ...prevColor, [input]: true }));
  };
  const resetColorOnLeave = (input) => {
    setTimeout(() => {
      setColor((prevColor) => ({ ...prevColor, [input]: false }));
    }, 100);
  };
  return (
    <Box
      flex={1}
      sx={{
        backgroundColor: { xs: "none", md: "#fff" },
        // borderRadius: "5px",
        // width: "100px",

        marginTop: "0rem",
        height: "90vh",
      }}
    >
      <Box position="sticky">
        <List
          sx={{
            display: { xs: "none", md: "block" },
            padding: "1rem 1rem 0 1rem",
          }}
        >
          <motion.div
            whileHover={{
              x: 10,
              backgroundColor: "#FF0000",
              color: "#fff",
              borderRadius: "5px",
            }}
            whileTap={{ scale: 0.9 }}
            onMouseEnter={() => setColorOnHover("appointment")}
            onMouseLeave={() => resetColorOnLeave("appointment")}
          >
            <ListItem disablePadding>
              <Link to="/customer/dashboard">
                <ListItemButton>
                  <ListItemIcon>
                    <TbCalendarTime
                      style={{
                        fontSize: "20px",
                        color: color.appointment ? "#fff" : "#212b36",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Appointment"
                    style={{
                      fontSize: "20px",
                      color: color.appointment ? "#fff" : "#212b36",
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </motion.div>
          <br />
          <motion.div
            whileHover={{
              x: 10,
              backgroundColor: "#FF0000",
              color: "#fff",
              borderRadius: "5px",
            }}
            whileTap={{ scale: 0.9 }}
            onMouseEnter={() => setColorOnHover("profile")}
            onMouseLeave={() => resetColorOnLeave("profile")}
          >
            <ListItem disablePadding>
              <Link to="/customer/dashboard/profile">
                <ListItemButton
                  sx={{
                    borderRadius: "5px",
                  }}
                >
                  <ListItemIcon>
                    <HiUser
                      style={{
                        fontSize: "20px",
                        color: color.profile ? "#fff" : "#212b36",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary=" Profile"
                    style={{
                      fontSize: "20px",
                      color: color.profile ? "#fff" : "#212b36",
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </motion.div>
          <br />
          <motion.div
            whileHover={{
              x: 10,
              backgroundColor: "#FF0000",
              color: "#fff",
              borderRadius: "5px",
            }}
            whileTap={{ scale: 0.9 }}
            onMouseEnter={() => setColorOnHover("create")}
            onMouseLeave={() => resetColorOnLeave("create")}
          >
            <ListItem disablePadding>
              <Link to="/services">
                <ListItemButton>
                  <ListItemIcon>
                    <FaCalendarPlus
                      style={{
                        fontSize: "17px",
                        color: color.create ? "#fff" : "#212b36",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Book"
                    style={{
                      fontSize: "20px",
                      color: color.create ? "#fff" : "#212b36",
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </motion.div>
          <br />
          <motion.div
            whileHover={{
              x: 10,
              backgroundColor: "#FF0000",
              color: "#fff",
              borderRadius: "5px",
            }}
            whileTap={{ scale: 0.9 }}
            onMouseEnter={() => setColorOnHover("home")}
            onMouseLeave={() => resetColorOnLeave("home")}
          >
            <ListItem disablePadding>
              <Link to="/">
                <ListItemButton>
                  <ListItemIcon>
                    <AiFillHome
                      style={{
                        fontSize: "20px",
                        color: color.home ? "#fff" : "#212b36",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Home &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;"
                    sx={{ color: color.home ? "#fff" : "#212b36" }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </motion.div>

          <br />
          <motion.div
            whileHover={{
              x: 10,
              backgroundColor: "#FF0000",
              color: "#fff",
              borderRadius: "5px",
            }}
            whileTap={{ scale: 0.9 }}
            onMouseEnter={() => setColorOnHover("logout")}
            onMouseLeave={() => resetColorOnLeave("logout")}
          >
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogOut}>
                <ListItemIcon>
                  <BiLogOut
                    style={{
                      fontSize: "20px",
                      color: color.logout ? "#fff" : "#212b36",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  sx={{ color: color.logout ? "#fff" : "#212b36" }}
                />
              </ListItemButton>
            </ListItem>
          </motion.div>
        </List>
      </Box>
    </Box>
  );
};

export default SidebarProfile;
