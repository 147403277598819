import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// import { makeStyles } from '@mui/styles';
import { TbCalendarTime } from "react-icons/tb";
import { GoPerson } from "react-icons/go";
import { BiLogOut } from "react-icons/bi";
import { FaCalendarPlus } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseCircle, AiFillHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import useLogout from "../../../Hooks/useLogout";
import { useSnackbar } from "notistack";
import { MdClose } from "react-icons/md";

const MobileView = () => {
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const logOut = useLogout();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogOut = () => {
    toggleDrawer();
    Swal.fire({
      title: "Confirmation",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#9de0f6",
      cancelButtonColor: "#fb464c",
      focusConfirm: false,
      inputAutoFocus: false,
      backdrop: "static",
      allowOutsideClick: false,
      confirmButtonText: "Confirm",
      focusCancel: false,
      text: `Are you sure you want to logout?`,
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          await logOut();
          navigate("/");
        }
      })
      .catch((error) => {
        enqueueSnackbar("Sorry, Something went wrong. Please try again", {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <GiHamburgerMenu
        onClick={toggleDrawer}
        style={{ color: "black", fontSize: "25px" }}
      />
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <Box sx={{ width: { xs: "250px", sm: "400px" } }}>
          <div styles={{ posiiton: "relative" }}>
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "40px",
                color: "#FC2F32",
              }}
            >
              <MdClose />
            </IconButton>
          </div>
          <br />
          <List sx={{ marginTop: "3rem" }}>
            <br />
            <motion.div
              whileHover={{
                x: 10,
                backgroundColor: "#FC2F32",
                color: "#ffffff",
              }}
              whileTap={{ scale: 0.9 }}
            >
              <Link to="/customer/dashboard">
                <ListItemButton>
                  <ListItemIcon>
                    <TbCalendarTime style={{ fontSize: "20px" }} />
                  </ListItemIcon>

                  <ListItemText primary="Appointment" />
                </ListItemButton>
              </Link>
            </motion.div>
            <br />
            <motion.div
              whileHover={{
                x: 10,
                backgroundColor: "#FC2F32",
                color: "#ffffff",
              }}
              whileTap={{ scale: 0.9 }}
            >
              <Link to="/customer/dashboard/profile">
                <ListItemButton>
                  <ListItemIcon>
                    <GoPerson style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText primary="User Profile" />
                </ListItemButton>
              </Link>
            </motion.div>
            <br />
            <motion.div
              whileHover={{
                x: 10,
                backgroundColor: "#FC2F32",
                color: "#ffffff",
              }}
              whileTap={{ scale: 0.9 }}
            >
              <Link to="/services">
                <ListItemButton>
                  <ListItemIcon>
                    <FaCalendarPlus style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Create Appointment" />
                </ListItemButton>
              </Link>
            </motion.div>
            <br />
            <motion.div
              whileHover={{
                x: 10,
                backgroundColor: "#FC2F32",
                color: "#ffffff",
              }}
              whileTap={{ scale: 0.9 }}
            >
              <Link to="/">
                <ListItemButton>
                  <ListItemIcon>
                    <AiFillHome style={{ fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </Link>
            </motion.div>
            <br />
            <motion.div
              whileHover={{
                x: 10,
                backgroundColor: "#FC2F32",
                color: "#ffffff",
              }}
              whileTap={{ scale: 0.9 }}
            >
              <ListItemButton onClick={handleLogOut}>
                <ListItemIcon>
                  <BiLogOut style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </motion.div>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};
export default MobileView;
