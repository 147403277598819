import { useState, useEffect } from "react";

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  const updateValue = (updatedProperties) => {
    setValue((prevValue) => {
      const newValue = { ...prevValue, ...updatedProperties };
      localStorage.setItem(key, JSON.stringify(newValue));
      return newValue;
    });
  };

  return [value, updateValue];
};

const getStoredValue = (key) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

const useClearLocalStorageProperty = (propertyKey) => {
  useEffect(() => {
    localStorage.removeItem(propertyKey);
  }, [propertyKey]);
};

export { useLocalStorage, getStoredValue, useClearLocalStorageProperty };
