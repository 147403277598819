import { useState, useEffect } from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import BeforeAfter from "../BeforeAfter/BeforeAfter";
import "./qr_style.css";

function HomeQRCode() {
  return (
    <>
      <div className="qr_before_con">
        <ElfsightWidget widgetId="6c81ac86-6a6a-439a-a26d-97e56becff42" />
        {/* <div className="elfsight-app-2a9622ad-dd57-46d5-bf8b-40f4304df571"></div> */}
      </div>
    </>
  );
}

export default HomeQRCode;
