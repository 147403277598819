import React from "react";
import styles from "./LoadingScreen.module.css";
import styled from "@emotion/styled";

const StyledCont = styled.div`
  // * {
  //   box-sizing: border-box;
  // }

  // body {
  //   margin: 10px 10px;
  //   padding: 10px 10px;
  //   display: grid;
  //   place-items: center;
  //   min-height: 100vh;
  // }
  position: relative;

  .paytm-loader {
    color: #002e6e;
    width: 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
    // transform: translateX(-38px);
    animation: loader 0.5s infinite alternate linear;
    -webkit-box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
    -webkit-transform: translateX(-38px);
    -webkit-animation: loader 0.5s infinite alternate linear;
  }

  @keyframes loader {
    50% {
      box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
    }
    100% {
      box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
    }
  }

  @-webkit-keyframes loader {
    50% {
      box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
    }
    100% {
      box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
    }
  }
`;
function Loader() {
  return (
    <StyledCont>
      <section class="paytm-loader"></section>
    </StyledCont>
  );
}

export default Loader;
