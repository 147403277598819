import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import {
  AiFillCaretUp,
  AiOutlineCaretDown,
  AiOutlineClose,
} from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import "./Header.scss";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import DanritLogo from "../../Assets/Images/Icons/danrit_logo.png";
// =========================================================
import { alpha } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import useAuth from "./../../Hooks/useAuth";
import AccountPopover from "./AccountPopover";
import Navbar from "./Navbar";

function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { auth } = useAuth();
  const open = Boolean(anchorEl);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isRegistrationPage = location.pathname === "/register";
  const isAdminRoute = location.pathname.startsWith("/admin");
  const isCustomerRoute = location.pathname.startsWith("/customer");
  const isMailConfirmation = location.pathname.startsWith(
    "/email-confirmation"
  );
  const isPasswordRest = location.pathname.startsWith("/reset-password");

  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 800 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  if (
    isLoginPage ||
    isRegistrationPage ||
    isAdminRoute ||
    isMailConfirmation ||
    isPasswordRest ||
    isCustomerRoute
  ) {
    return null; // Render nothing for login and registration routes
  }

  return (
    <header style={{ position: "sticky", top: 0, zIndex: 10 }}>
      <Navbar />
      {/* {dropdown && <Dropdown />} */}
    </header>
  );
}

export default Header;
